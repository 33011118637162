import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"



const Slider = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query  {
          allStrapiArticles(limit: 6, skip: 0, sort: {fields: createdAt, order: ASC}, filter: {status: {eq: "published"}}) {
            edges {
              node {
                strapiId
                id
                excerpt
                createdAt
                title
                isFeatured
                category {
                  name
                }
                banner {
                      childImageSharp {
                        fixed(width: 400, height: 500) {
                          srcSet
                          width
                          height
                          src
                          aspectRatio
                        }
                      }
                    }
              }
            }
          }
        }


      `}
      render={({ allStrapiArticles }) => {

        return (
          <div className="uk-section-primary uk-section uk-section-large">
            <div className="uk-container"><div className="uk-grid-margin uk-grid uk-grid-stack" uk-grid="">
              <div className="uk-flex-auto uk-width-1-1@m uk-first-column">
                <h1 className="uk-text-left">SUBMIT YOUR BIKE</h1>
              </div>
            </div><div className="uk-grid-margin uk-grid" uk-grid="">
                <div className="uk-flex-auto uk-width-2-3@m uk-first-column">
                  <div className="uk-text-lead uk-margin uk-text-left">
                    Big or Small, let us take a look at your bike!
                    <br/>
                    Send an email to <a href="mailto:motominuto@gmail.com">motominuto@gmail.com</a>
                  </div>
                </div>
                <div className="uk-flex-auto uk-width-1-3@m">
                  <div className="uk-margin-medium uk-text-center">
                    <a className="el-content uk-button uk-button-default" href="mailto:motominuto@gmail.com">Send Now!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      }
    />
  )
}

export default Slider
