import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import SubmitStory from './submit-story'
import Footer from "./footer"
import Seo from "./seo"

const Layout = ({ children }) => {
  return (
    <>
      {/* <Seo /> */}
      <div className="head-banner-wrap">
        <Nav />
      </div>
      <main>{children}</main>
      <SubmitStory />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout  